<template>
  <div class="qrcode-items">
    <router-back></router-back>
    <div class="qrcode-items-body">
      <el-form label-width="80px" inline size="small">
        <el-form-item label="取货码名称:">{{qrcode.name}}</el-form-item>
        <el-form-item label="已使用/总量:">{{qrcode.used}}/{{qrcode.total}}</el-form-item>
        <el-form-item label="使用状态:">
          <el-select v-model="pagination.status" @change="loadQrCode">
            <el-option :key="filter.value"
                       :label="filter.name"
                       :value="filter.value" v-for="filter in filters"></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <el-table
        class="qrcode-items-list"
        :data="items"
        style="width: 100%">
        <empty slot="empty"></empty>
        <el-table-column
          prop="sn"
          label="取货编号">
        </el-table-column>

        <el-table-column
          prop="date"
          align="center"
          width="60"
          label="状态">
          <template slot-scope="scope">
            {{scope.row.status | filterStatus}}
          </template>
        </el-table-column>
        <el-table-column
          label="剩余可用"
          align="center"
          width="80">
          <template slot-scope="scope">
            {{qrcode.maxUseTime - scope.row.times}}
          </template>
        </el-table-column>

        <el-table-column
          label="已用次数"
          prop="times"
          align="center"
          width="80">
        </el-table-column>




        <el-table-column
          align="center"
          label="最近使用时间">
          <template slot-scope="scope">
            <span v-if="scope.row.lastUpdatedAt === 0">未使用</span>
            <span v-else> {{new Date(scope.row.lastUpdatedAt * 1000).Format('yyyy-MM-dd hh:mm:ss')}}</span>
          </template>
        </el-table-column>


        <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="240">
          <template slot-scope="scope">
            <template v-if="(qrcode.maxUseTime - scope.row.times) > 0">
              <el-link :underline="false" class="bold"
                       @click="handleFreeze('冻结', scope.row.sn, 3,scope.row)"
                       type="primary" size="small"
                       v-if="scope.row.status !== 3">冻结</el-link>
              <el-link :underline="false" class="bold freeze"
                       @click="handleFreeze('解冻', scope.row.sn, 2,scope.row)"
                       type="primary" size="small"
                       v-if="scope.row.status === 3">解冻</el-link>
              <el-link :underline="false"
                       @click="handleShowQr(scope.row.sn)"
                       type="primary"
                       size="small">查看二维码</el-link>
            </template>
            <span v-else>-</span>
          </template>

        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="handleCurrentChange"
      class="pagination"
      :current-page="pagination.curPage"
      :page-size="pagination.limit"
      layout="total, prev, pager, next, jumper"
      :total="pagination.total">
    </el-pagination>

    <c-dialog :visible.sync="qrVisible" class="qr-dialog" :title="'取货码'">
      <qrcode :value="qrUrl" :options="{ width: 160 }" style="margin-top: 36px"></qrcode>
    </c-dialog>
  </div>

</template>

<script>
  import {qrcodeItemUnion, qrCodeItemList, qrCodeItemFreeze} from '@/api/marketing/qrcode'
  import CDialog from '@/components/Dialog'
  import {mapGetters} from "vuex";
  export default {
    name: 'qr-code-items',
    computed: {
      ...mapGetters(['employee'])
    },
    components: {
      CDialog
    },
    activated: function () {
      let param = this.$route.params
      this.qrcode.name = param.name
      this.qrcode.total = param.total
      this.qrcode.maxUseTime = param.maxUseTime
      this.qrcode.used = param.used
      this.pagination.uuid = param.uuid
      this.loadQrCode()
    },
    data: function () {
      return {
        filters: [
          {name: '全部', value: 0},
          {name: '未使用', value: 1},
          {name: '已使用', value: 2},
          {name: '已冻结', value: 3},
        ],
        items: [],
        qrUrl: '',
        qrVisible: false,
        qrcode: {
          uuid: '',
          name: '',
          total: 0,
          used: 0,
        },
        pagination: {
          uuid: '',
          status: 0,
          curPage: 1,
          limit: 10,
          total: 0,
          orderType: 0,
        },
      }
    },
    filters: {
      filterStatus(st) {
        let status = ['全部', '未使用', '已使用', '已冻结']
        return status[st]
      }
    },
    methods: {
      handleCurrentChange(value) {
        this.pagination.curPage = value
        this.pagination.start = (value - 1) * this.pagination.limit
        qrCodeItemList(this.pagination).then(res => {
          this.items = res.data || []
        })
      },
      handleShowQr(sn) {
        this.qrUrl = `http://m.yunka-it.cn/pay/#/qr?bid=${this.employee.enterpriseUuid}&sn=${sn}`
        this.qrVisible = true
      },
      loadQrCode() {
        qrcodeItemUnion(this.pagination).then(res => {
          this.items = res.data.list || []
          this.pagination.total = parseInt(res.data.total)
        })
      },
      handleFreeze(text, sn, status, value) {
        this.$confirm(`是否${text}条码`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          qrCodeItemFreeze({sn: sn, status: status}).then(res => {
            value.status = status
          })
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .qrcode-items {
    &-body {
      /*max-width: 720px;*/
      /*margin: 0 auto;*/
      padding: $padding;

      .el-form-item {
        min-width: 240px;
        padding-left: $padding-8;
      }

      .el-link {
        margin-right: $padding-8;
        margin-left: $padding-8;
        font-size: 13px;
        font-weight: normal;
      }
      .freeze{
        color: $btn_color !important;
        font-weight: 500;
      }

      .el-link:hover, .el-link:focus {
        font-weight: 500;
      }

      .el-link--primary:hover {
        color: $_theme_color;
      }
    }
    .qr-dialog{
      text-align: center;
    }
  }
</style>
